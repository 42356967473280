
import {
  navigateTo,
  usePinia,
  useSearchStore,
  useSearchWizardStore,
} from '#imports'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import { getUrlPrefix } from '../../../../lang/locales'
import { GeoCircle } from '../../../../utility/geo/GeoLocation'
import { SearchParty } from '../../../search/schemas'
import { ArriveDepartIso } from '../../../search/types'
import { LocationWizardData } from '../../domain/LocationWizardData'
import { getSuggestionsMachine } from '../../inversify.config'
import { SearchWizardFilters } from '../domain/SearchWizardFilters'
import { SuggestionsMachineInterpret } from '../location/machine/suggestionsMachine'
import { CampsitesSearchWizardMainProps } from './CampsitesSearchWizardMainProps'
import { SearchLinkGenerator } from '../../../url/generators/SearchLinkGenerator'

@Component<CampsitesSearchWizardMain>({
  setup() {
    const searchStore = useSearchStore(usePinia())
    const searchWizardStore = useSearchWizardStore(usePinia())
    if (searchWizardStore.isSearchWizardOpen === null)
      searchWizardStore.isSearchWizardOpen = true
    return { searchWizardStore, searchStore }
  },
})
export default class CampsitesSearchWizardMain
  extends Vue
  implements CampsitesSearchWizardMainProps
{
  searchStore: ReturnType<typeof useSearchStore>
  searchWizardStore: ReturnType<typeof useSearchWizardStore>

  @Prop({ default: false })
    showOpenCategories: boolean

  @Prop()
    query?: string

  suggestionsMachine: SuggestionsMachineInterpret | null = null
  suggestionsState: SuggestionsMachineInterpret['state'] | null = null
  inputIsFocused = false

  get context() {
    return this.searchWizardStore.context
  }

  editMode = false

  get isClearSearch() {
    return this.searchWizardStore.isClearSearch
  }

  forceSuggestionsOpen = false

  get totalCount() {
    return this.searchWizardStore.totalCounts
  }

  updateSuggestions(input: string) {
    this.suggestionsMachine?.send({ type: 'SET_INPUT', input })
  }

  selectLocation(name: string) {
    this.suggestionsMachine?.send({ type: 'SELECT_LOCATION', name })
  }

  async mounted() {
    this.suggestionsMachine = getSuggestionsMachine(
      this.$route.params.lang || 'en-gb',
    )
    this.suggestionsMachine.onTransition((state) => {
      this.suggestionsState = state
    })
    this.suggestionsMachine.send({
      type: 'SET_INITIAL_INPUT',
      input: this.searchInputValue,
    })
    this.forceSuggestionsOpen = !!this.query
    await this.isSearchWizardOpenChanged()
    if (this.showOpenCategories) {
      this.openCategories()
    }
  }

  clearInput() {
    this.searchWizardStore.send({ type: 'CLEAR_INPUT' })
  }

  get inputPlaceholder() {
    return this.$t(
      this.inputIsFocused
        ? 'Where are you going?'
        : this.searchWizardStore.inputPlaceholder,
    ).toString()
  }

  get searchInputValue() {
    return (
      (this.context?.searchInputValue?.type === 'CUSTOM' &&
        this.context?.searchInputValue?.text) ||
      ''
    )
  }

  set searchInputValue(value: string) {
    // this.searchWizardStore.send({ type: 'SET_INPUT_VALUE', value })
  }

  get searchFilters(): SearchWizardFilters {
    return this.searchWizardStore.wizardFilters
  }

  get wizardOpen() {
    return this.searchWizardStore.openOnFocus
      ? this.searchWizardStore.isSearchWizardOpen
      : true
  }

  @Watch('searchWizardStore.isSearchWizardOpen')
  isSearchWizardOpenChanged() {
    if (!this.searchWizardStore.isSearchWizardOpen) return
    this.openSuggestions()
  }

  private openSuggestions() {
    if (this.query) {
      this.openLocation()
      this.updateSuggestions(this.query)
    }
  }

  get showSummary() {
    return this.matches('idle')
  }

  openLocation() {
    if (!this.searchWizardStore.isSearchWizardOpen) {
      this.searchWizardStore.isSearchWizardOpen = true
      this.searchWizardStore.send('FOCUS_LOCATION')
    }
  }

  changeLocation({
    location,
    term,
  }: {
    location: LocationWizardData
    term?: string
  }) {
    this.searchWizardStore.send('SET_LOCATION', {
      data: location,
      inputValue: term,
    })
    this.cancelEdit()
    this.updateCounts()
  }

  openCategories() {
    this.searchWizardStore.send('EDIT_CATEGORIES')
  }

  get categoryIds(): string[] {
    return this.searchWizardStore.wizardFilters.categoryIds || []
  }

  set categoryIds(value: string[]) {
    this.searchWizardStore.send({
      type: 'SET_CATEGORIES',
      categoryIds: value,
    })
    this.updateCounts()
  }

  clickHandler() {
    this.openLocation()
  }

  get locationInputFocused(): boolean {
    return this.matches('idle.locationFocus')
  }

  openDates() {
    this.searchWizardStore.send('EDIT_DATES')
  }

  get dates(): ArriveDepartIso | undefined {
    return this.searchWizardStore?.wizardFilters?.dates
  }

  set dates(dates: ArriveDepartIso | undefined) {
    this.searchWizardStore.send({
      type: 'SET_DATES',
      dateRange: dates || null,
    })
    this.updateCounts()
  }

  get hasBounds() {
    return !!this.searchWizardStore?.wizardFilters?.bounds
  }

  openGuests() {
    this.searchWizardStore.send('EDIT_GUESTS')
  }

  get guests(): SearchParty {
    return (
      this.searchWizardStore?.wizardFilters?.party || {
        adults: 2,
        childAges: [],
      }
    )
  }

  set guests(guests: SearchParty) {
    this.searchWizardStore.send({ type: 'SET_GUESTS', guests })
    this.updateCounts()
  }

  get guestsEnabled() {
    return !!(this.dates?.arrive && this.dates?.depart)
  }

  get area(): GeoCircle | undefined {
    return this.searchWizardStore?.wizardFilters?.area
  }

  get searchEnabled() {
    return (
      this.searchWizardStore.isSearchEnabled ||
      this.$route.name === 'index' ||
      this.$route.name?.includes('searchedit')
    )
  }

  clearDates() {
    this.searchWizardStore.send({
      type: 'CLEAR_DATES',
    })
    this.updateCounts()
  }

  modifyWithin(within: number) {
    this.searchWizardStore.send('SET_WITHIN', { within })
    this.updateCounts()
  }

  cancelEdit() {
    this.searchWizardStore.send('CANCEL_EDIT')
  }

  close() {
    // this is saying if on the homepage cloase it
    if (this.$route.name === 'index') {
      Vue.nextTick(() => {
        this.$puScrollTo('[data-back-to-top]', {
          duration: 200,
        })
      })
      this.searchWizardStore.isSearchWizardOpen = false
    } else {
      // otherwise just go back nav
      this.$router.go(-1)
    }
  }

  clear() {
    this.searchWizardStore.send('CLEAR_SEARCH_PARAMS')
    this.suggestionsMachine?.send({ type: 'SET_INPUT', input: '' })
    this.updateCounts()
  }

  locationSectionFocussed() {
    this.inputIsFocused = true
    this.searchWizardStore.isSearchWizardOpen = true
    Vue.nextTick(() => {
      this.$puScrollTo('[data-search-location-scroll-target]', {
        duration: 300,
        offset: -18,
      })
    })
  }

  get selectedLocation() {
    return this.context.selectedLocation
  }

  get selectedLocationType() {
    return this.selectedLocation?.dataType || null
  }

  get searchStepOpen() {
    return !this.matches('idle')
  }

  matches(stateString: string): boolean {
    return this.searchWizardStore.states.includes(stateString)
  }

  // TODO: move to machine
  updateCounts() {
    this.searchWizardStore.updateCounts().catch((e) => console.warn(e))
  }

  async formSubmit(event) {
    event.preventDefault()
    if (!this.searchEnabled) {
      return
    }
    await navigateTo({
      path: this.formActionUrl,
      query: this.getSearchURL().query as Record<
      string,
      string | string[] | undefined
      >,
    })
  }

  get formActionUrl() {
    const langPrefix = getUrlPrefix(this.$route.params.lang)
    if (this.selectedLocation?.dataType === 'campsite') {
      return `${langPrefix}/campsites/${this.selectedLocation.hierarchyPath}${this.selectedLocation.slug}`
    }
    const searchUrlPath = this.getSearchURL().path
    const path = `${langPrefix}${searchUrlPath}`
    if (this.$route.name === 'searcheditmaps-all') {
      return path.replace('/search/', '/maps/')
    }
    if (this.$route.name?.includes('searchnew')) {
      return path.replace('/search/', '/searchnew/')
    }
    return path
  }

  getSearchURL() {
    const urlGenerator = new SearchLinkGenerator()
    const urlData = urlGenerator.getUrlData({
      category: this.$route.name === 'searcheditmaps-all' ? 'maps' : 'search',
      excludeType: 'searchWizard',
      filters: this.searchFilters,
    })

    return urlData
  }
}
